import React from 'react'

function DeliveryOption({data}) {
  return (
    <div className='form-results'>
        <h2>N3E Delivery Form</h2>
        <table className=''>
            <tbody>
                <tr><td>Name:</td><td>{data.firstName + " " + data.lastName}</td></tr>
                <tr><td>Email:</td><td>{data.email}</td></tr>
                <tr><td>Phone:</td><td>{data.phone}</td></tr>
                <tr><td>Delivery Type:</td><td>{data.deliveryType}</td></tr>
                <tr><td>Building Type:</td><td>{data.buildingType}</td></tr>
                <tr><td>Space for Truck?:</td><td>{data.hasSpaceForTruck}</td></tr>
                <tr><td>Require Prenotification?:</td><td>{data.requirePreNotification}</td></tr>
                <tr><td>Require Appointment?:</td><td>{data.requireDeliveryAppointment}</td></tr>
            </tbody>
        </table>
        <h4>Additional Notes:</h4>
        <p>{data.additionalNotes}</p>
    </div>

  )
}

export default DeliveryOption