import { useState, useEffect, useContext, useCallback} from 'react'
import SiteContext from '../contexts/SiteContext'
import Loader from './Loader'

function Products({orderItems}) {
const [itemList, setItemList] = useState([])
const {findProductName, findBundle} = useContext(SiteContext)

// function to format money
var mF = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

// * Turn all order items into an array of objects.
    const makeItemList = useCallback((arr) => {

        let newList = []  // Empty List to be populated
        let prod = ''
        arr.forEach(obj => {
            if (obj.product_bundle === null) {
                prod = findProductName(String(obj.product))

                if (obj.selectedOption !== null) {
                    prod = prod + ` - ${obj.selectedOption}`
                }

                newList.push({
                    title: prod,
                    qty: obj.quantity,
                    cost: mF.format(obj.subTotal),
                    tax: mF.format(obj.tax),
                })
            } else {
                newList.push({
                    title: findBundle(String(obj.product_bundle)),
                    qty: obj.quantity,
                    cost: mF.format(obj.subTotal),
                    tax: mF.format(obj.tax),
                })
                obj.VariantOptions.forEach(option => {
                    prod = option.product.title
                    if (option.selectedOption !== null) {
                        prod = prod + ` - ${option.selectedOption}`
                    }

                    newList.push({
                        title: "- " + prod,
                        qty: 1,
                        cost: "-",
                        tax: "-"
                    })
                })
            }
        })

        setItemList(newList)
    }, [findProductName, findBundle])

    useEffect(() => {
        makeItemList(orderItems)
    }, [orderItems, makeItemList])

    if (itemList === []){
        return <Loader />
    }

    return (
        <>
        {itemList.map((item, index) => 
            <tr key={index}><td>{item.title}</td><td>x {item.qty}</td><td>{item.cost}</td><td>{item.tax}</td></tr>
        )}
        </>
    )
}

export default Products