import Login from './components/Login';
import Orders from './components/Orders';
import Order from './components/Order';
import PrivateRoute from './components/PrivateRoute';
import Logic from './components/Logic';
import Header from './components/Header';
import './App.scss';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {

      return (
        <div className='App'>
          <Router>
            <Header />
            <Logic />
            <Routes>
              <Route path='/login' element={<Login />} />
                <Route exact path='/' element={
                  <PrivateRoute>
                    <Orders/>
                  </PrivateRoute>
                  } />
                  <Route path='/order'> 
                  <Route path=':id' element={
                    <PrivateRoute>
                    <Order />
                  </PrivateRoute>
                  } />
                  </Route>
            </Routes>
          </Router>
        </div>
      )
}

export default App;

