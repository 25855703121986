import React from 'react';
import Tracking from './Tracking';
import Products from './Products';
import DeliveryOption from './DeliveryOption';
import RefundForm from './RefundForm';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import format from 'date-fns/format';
// import { fetchOrder, fetchProductQl } from '../apis/apis'
import SiteContext from '../contexts/SiteContext';

function Order() {
	const [rerender, setRerender] = useState(0);
	const { id } = useParams();
	const navigate = useNavigate();
	const { loading, setLoading, updateOrderStatus, fetchOrder, updateTracking } =
		useContext(SiteContext);
	const [orderStatus, setOrderStatus] = useState('');
	const [orderData, setOrderData] = useState({});
	const [courier, setCourier] = useState('');
	const [tracking, setTracking] = useState('');
	const [hasChanged, setHasChanged] = useState(false);
	const [refundFormOpen, setRefundFormOpen] = useState(false);
	const [msg, setMsg] = useState({
		status: 'success',
		message: '',
	});

	var mF = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	useEffect(() => {
		fetchOrder(id).then((data) => {
			setOrderData(data);
		});
	}, [id, rerender]);

	useEffect(() => {
		if (orderData.data !== undefined) {
			setOrderStatus(orderData.data.orderStatus);
			setCourier(orderData.data.courier?.id);
			setTracking(orderData.data.trackingNumber);
		}
	}, [orderData]);

	useEffect(() => {
		if (orderStatus !== '') {
			setLoading(false);
		}
	}, [orderStatus]);

	// v------------ Form Handlers -----------------v

	const handleStatusChange = (e) => {
		setOrderStatus(e);
		setHasChanged(true);
	};

	const handleStatusSumbit = (e) => {
		e.preventDefault();
		updateOrderStatus(id, orderStatus);
		setHasChanged(false);
	};

	if (loading) {
		return <h1>Loading...</h1>;
	} else {
		return (
			<>
				<div className="back-button">
					<button onClick={() => navigate('/')}>&lt; Back</button>
				</div>
				<h1>
					Order #{orderData.data.orderId} - {orderData.data.shippingAddress.firstName}{' '}
					{orderData.data.shippingAddress.lastName}
				</h1>
				<div className="order-items">
					<table className="product-list">
						<thead>
							<tr>
								<th className="product-col">Product</th>
								<th className="qty-col">Qty</th>
								<th>Total</th>
								<th>Tax</th>
							</tr>
						</thead>
						<tbody>
							<Products orderItems={orderData.data.order_items} />
						</tbody>
					</table>
					<div className="coupons-container">
						<div className="coupons">
							<h5>Coupons: </h5>
							{orderData.data.coupons === [] ? (
								<p>None</p>
							) : (
								orderData.data.coupons.map((coupon, index) => {
									return (
										<>
											<p key={index}>
												{coupon.code}
												{index < orderData.data.coupons.length - 1 ? ',' : ''}
											</p>
										</>
									);
								})
							)}
						</div>
					</div>
					<div className="totals">
						{orderData.data.payment && (
							<div className="payment-method">
								<h5>Payment Method:</h5>
								<p>{orderData.data.payment?.method}</p>
								<h5>Paid At:</h5>
								<p>{format(new Date(orderData.data.payment?.created_at), 'MMM, dd, y - ppp')}</p>
								<h5>Status:</h5>
								<p>{orderData.data.payment?.status}</p>
								{orderData.data.payment?.status !== 'Refunded' && (
									<button onClick={() => setRefundFormOpen(!refundFormOpen)}>Refund</button>
								)}
							</div>
						)}
						<div className="lists label">
							<p>Subtotal:</p>
							{orderData.data.discount !== null && <p>Discount:</p>}
							<p>Tax &#40;{orderData.data.shippingAddress.state}&#41;:</p>
							<p>Total:</p>

							{(orderData.data.payment?.status === 'Paid' || 'PartiallyRefunded' || 'Refunded') && (
								<>
									<hr />
									<p>Paid:</p>
									<p>Refunded:</p>
								</>
							)}
						</div>
						<div className="lists values">
							<p>{mF.format(orderData.data.subTotal)}</p>
							{orderData.data.discount !== null && <p>-{mF.format(orderData.data.discount)}</p>}
							<p>{mF.format(orderData.data.tax)}</p>
							<p>{mF.format(orderData.data.total)}</p>
							{(orderData.data.payment?.status === 'Paid' || 'PartiallyRefunded' || 'Refunded') && (
								<>
									<hr />
									<p>{mF.format(orderData.data.payment.amount)}</p>
									<p>{mF.format(orderData.data.payment.amountRefunded)}</p>
								</>
							)}
						</div>
					</div>
					<div className="message">
						<h5 className={msg?.status}>{msg.message}</h5>
					</div>
					{refundFormOpen && orderData.payment?.status !== 'Refunded' ? (
						<RefundForm
							data={orderData.data}
							setRerender={setRerender}
							rerender={rerender}
							key={rerender}
							setMsg={setMsg}
						/>
					) : (
						<></>
					)}
					<div className="status-form">
						<h2>Order Details</h2>
						<form action="" onSubmit={(e) => handleStatusSumbit(e)}>
							<label htmlFor="status">Order Status</label>
							<select
								name="status"
								id="order-status"
								value={orderStatus}
								onChange={(e) => handleStatusChange(e.target.value)}
							>
								<option value="...">...</option>
								<option value="processing">processing</option>
								<option value="completed">completed</option>
								<option value="onhold">on hold</option>
								<option value="pending">pending</option>
								<option value="cancelled">cancelled</option>
								<option value="failed">failed</option>
								<option value="refunded">refunded</option>
							</select>
							<input type="submit" className={hasChanged ? '' : 'hide'} />
						</form>
						<div style={{ border: 'solid 1px', padding: '5px' }}>
							<h4>Tracking Number</h4>
							<Tracking
								setCourier={setCourier}
								setTracking={setTracking}
								courier={courier}
								tracking={tracking}
								updateTracking={updateTracking}
								id={id}
							/>
						</div>
					</div>
					<div className="addresses">
						<div className="address">
							<h4>Shipping</h4>
							<p>
								{orderData.data.shippingAddress.firstName} {orderData.data.shippingAddress.lastName}
							</p>
							{orderData.data.shippingAddress.companyName !== null && (
								<p>{orderData.data.shippingAddress.companyName}</p>
							)}
							<p>{orderData.data.shippingAddress.addressLine1}</p>
							{orderData.data.shippingAddress.addressLine2 !== null && (
								<p>{orderData.data.shippingAddress.addressLine2}</p>
							)}
							<p>
								{orderData.data.shippingAddress.city}, {orderData.data.shippingAddress.state}{' '}
								{orderData.data.shippingAddress.postalCode}
							</p>
						</div>
						<div className="address">
							<h4>Billing</h4>
							<p>
								{orderData.data.billingAddress.firstName} {orderData.data.billingAddress.lastName}
							</p>
							{orderData.data.billingAddress.companyName !== null && (
								<p>{orderData.data.billingAddress.companyName}</p>
							)}
							<p>{orderData.data.billingAddress.addressLine1}</p>
							{orderData.data.billingAddress.addressLine2 !== null && (
								<p>{orderData.data.billingAddress.addressLine2}</p>
							)}
							<p>
								{orderData.data.billingAddress.city}, {orderData.data.billingAddress.state}{' '}
								{orderData.data.billingAddress.postalCode}
							</p>
						</div>
					</div>
					<h4>Email: </h4>
					<p>{orderData.data.customerEmail}</p>
					<h4>Phone: </h4>
					<p>{orderData.data.customerPhone}</p>
					{orderData.data.delivery_option !== null && (
						<DeliveryOption data={orderData.data.delivery_option} />
					)}
				</div>
			</>
		);
	}
}

export default Order;
