import { useCallback, useContext, useState, useEffect } from 'react';
import SiteContext from '../contexts/SiteContext';
import Loader from './Loader';

function RefundForm({ data, setRerender, rerender, setMsg }) {
	const [amount, setAmount] = useState('full');
	const { authData } = useContext(SiteContext);
	const [partialRefund, setPartialRefund] = useState(false);
	const [awaitingRequest, setAwaitingRequest] = useState(false);

	var mF = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	useEffect(() => {}, [partialRefund]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMsg({
			status: '',
			message: '',
		});
		setAwaitingRequest(true);
		console.log(data.payment.transactionId);
		console.log(data.payment.method);

		if (!partialRefund) {
			setAmount('full');
		} else {
			setAmount(Number(amount).toFixed(2));
		}

		let res = await fetch(`${process.env.REACT_APP_API_URL}/orders/refund`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${authData.data.jwt}`, 'Content-Type': 'application/json' },
			body: JSON.stringify({
				charge:
					data.payment.method === 'affirm'
						? data.payment.paymentResponseJson.charge
						: data.payment.transactionId,
				paymentMethod: data.payment.method,
				id: data.id,
				amount: amount,
			}),
		}).catch((err) => {
			console.log(err);
			setMsg({
				status: 'error',
				message: `Error: ${err.data}`,
			});
		});
		setAwaitingRequest(false);
		let content = await res.json();
		if (content?.taskCompleted === 'true') {
			setMsg({
				status: 'success',
				message: 'Refund successful!',
			});
		}
		console.log(content);
		setRerender((rerender += 1));
	};

	const handleChange = (e) => {
		setAmount(e);
	};

	const handleCheck = (e) => {
		setPartialRefund(!partialRefund);
		if (partialRefund && isNaN(amount)) {
			setAmount(0);
		}
	};

	return (
		<div className="refund-form">
			<h4>Available to Refund: {mF.format(data.payment.amount - data.payment.amountRefunded)}</h4>

			<form onSubmit={(e) => handleSubmit(e)}>
				<label>Partial Refund?</label>
				<input type="checkbox" onChange={(e) => handleCheck(e)} />
				<br />
				{partialRefund && (
					<>
						<label>Amount to Refund: $</label>
						<input
							className="money"
							type="number"
							step="any"
							min="0.01"
							value={amount}
							onChange={(e) => handleChange(e.target.value)}
							placeholder="Ex. 150.54"
						/>
						<br />
					</>
				)}

				<input type="submit" disabled={awaitingRequest ? true : false}></input>
			</form>
		</div>
	);
}

export default RefundForm;
