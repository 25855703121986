// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import SiteContext from '../contexts/SiteContext'

export default function PrivateRoute({ children }) {
    const {loggedIn} = useContext(SiteContext)

    if (!loggedIn) {
        return <Navigate to="/login"></Navigate>
    }

    return children;

}
