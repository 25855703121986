import React, {useMemo} from 'react'

function PageGroup({handlePageChange, paginateGroup, pag, count}) {

    const getMaxPage = useMemo(() => {
        return (Math.ceil(count / pag.limit))
    }, [count, pag.limit])

  return (
    <div>
        {(pag.page !== 1) && <button onClick={() => handlePageChange(pag.page - 1)} >&#8592;</button>}
        
        {paginateGroup().map((num, i) => {
            if (num <= (getMaxPage)) {
            let cName = 'pag-button'
            if (pag.page === num) {
                cName = cName + ' selected'
            }

            return <button 
                    key={i} 
                    onClick={() => handlePageChange(num)}
                    className={cName}
                    >
                        {num}
                    </button>
            } else {
                return null
            }
        } )}
        {(pag.page !== getMaxPage) && <button onClick={() => handlePageChange(pag.page + 1)} >&#8594;</button>}
    </div>
  )
}

export default PageGroup