import React from 'react'
import { useContext, useEffect } from 'react'
import SiteContext from '../contexts/SiteContext'

function Logic() {
  const {getLocalJwt } = useContext(SiteContext)
  
  useEffect(() => {
    getLocalJwt()
  }, [])

  return (
    <></>
  )
}

export default Logic