import React from 'react'
import { useContext } from 'react'
import SiteContext from '../contexts/SiteContext'
import Logo from '../assets/NXS_darklogo.png'

function Header() {
    const {logOut, loggedIn} = useContext(SiteContext)

    const handleClick = () => {
        logOut()
    }

  return (
      <header>
        <img src={Logo} alt="" width="200" className='logo'/>
        {loggedIn && <button onClick={() => handleClick()} className="logout-button" >Log Out</button>}
      </header>
  )
}

export default Header