import { createContext, useState} from "react"
import axios from "axios"
import jwtDecode from "jwt-decode";
import useLocalStorage from "../hooks/useLocalStorag";

const SiteContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL

export const SiteProvider = ({children}) => {
  const [loading, setLoading] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [authData, setAuthData] = useLocalStorage({})
  const [productList, setProductList] = useState([])
  const [bundleList, setBundleList] = useState([])
  const [decodedJwt, setDecodedJwt] = useState({})
  const [logoutMsg, setLogoutMsg] = useState('')

  // Log out and manage states to logout
  const logOut = (msg = 'You have been logged out.') => {
    setAuthData({})
    setLoggedIn(false)
    setLogoutMsg(msg)
  }

  // grab jwt for local
  const getLocalJwt = () => {
    if (authData?.data?.jwt !== undefined) {
      setDecodedJwt(jwtDecode(authData.data.jwt))
      checkJwt()
      setLoggedIn(true)
      setLogoutMsg('')
    }
      
  }

  const fetchOrders = async (page = 1, sort = "desc", limit = 20) => {
    let start = (page -1) * (limit)
    let res = await axios.get(`${apiUrl}/orders?_start=${start}&_sort=orderId:${sort}&_limit=${limit}`, {
        headers: {Authorization: `Bearer ${authData.data.jwt}`}
    });
    return res.data
}

  const fetchOrder = async (orderNum) => {
    let res = await axios.get(`${apiUrl}/orders/${orderNum}`, {
        headers: {Authorization: `Bearer ${authData.data.jwt}`}
    });
    return res
}

  const fetchOrderCount = async (orderNum) => {
    let res = await axios.get(`${apiUrl}/orders/count`, {
      headers: {Authorization: `Bearer ${authData.data.jwt}`}
    });
    return res.data
  }

  // Check if JWT is expired, and log out if true.
  const checkJwt = () => {
    if (decodedJwt?.exp <= (Date.now()/1000)) {
      logOut('Your login token has expired. Please login again.')
    }
  }

  // send Login credentials and store response if success
  const sendLogin = async (usr, psw) => {
    let res = await axios.post(apiUrl + '/auth/local', {
        identifier: usr,
        password: psw,
    }).catch((error) => {
      setLoading(false);
    });
    setAuthData(res)
    checkAuth(res.status);
    setDecodedJwt(jwtDecode(res.data.jwt))
    // setJwt(res.data.jwt)
    setLoading(false);
  }

  // fetch and store an array of product objects { id, title}
  const fetchProductList = async () => {
    let res = await axios({
        url: `${apiUrl}/graphql`,
        method: 'post',
        data: {
            query: `
            query{
              products{
                id
                title
              }
            }
            `
        }
    })
    setProductList(res.data.data.products)
  }

  // fetch and store an array of bundle objects { id, title, product: {title} }
  const fetchBundleList = async () => {
    let res = await axios({
        headers: {Authorization: `Bearer ${authData.data.jwt}`},
        url: `${apiUrl}/graphql`,
        method: 'post',
        data: {
            query: `
            query{
              productBundles{
                id
                title
                product {
                  title
                }
              }
            }
            `
        }
    })
    setBundleList(res.data.data.productBundles)
  }


  const checkAuth = (status) => {
    if (status === 200) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }

  // Searh through product list with Id and return product title
  const findProductName = (prodId) => {
    return productList.find(item => item.id === prodId).title
  }

  // Searh through bundle list with Id and return bundle title
  const findBundle = (bundleId) => {
    let bundle = ""
    let name = ""
    bundle = bundleList.find(item => item.id === bundleId).title
    name = bundleList.find(item => item.id === bundleId).product.title

    return name + " " + bundle
  }

  // Send Graphql mutation to Strapi to update order on Backend
  const updateOrderStatus = async (order, status) => {
    let enumStatus = status.replace(/['"]+/g, '');
    let res = await axios({
        headers: {Authorization: `Bearer ${authData.data.jwt}`},
        url: `${apiUrl}/graphql`,
        method: 'post',
        data: {
            query: `
            mutation{
              updateOrder(
                input:{
                  where: {id: ${order}}
                  data: {orderStatus: ${enumStatus}}
                }
              ){
                order {
                 orderStatus
                }
              }
            }
            `
        }
    })
  }

  const updateTracking = async (order, courier, tracking) => {
    console.log(order)
    console.log(courier)
    console.log(tracking)
    let res = await axios({
        headers: {Authorization: `Bearer ${authData.data.jwt}`},
        url: `${apiUrl}/graphql`,
        method: 'post',
        data: {
            query: `
            mutation{
              updateOrder(
                input:{
                  where: {id: ${order}}
                  data: {courier: ${courier}, trackingNumber: "${tracking}"}
                }
              ){
                order {
                 courier {id}
                  trackingNumber
                }
                
              }
            }
            `
        }
    }).catch(err => console.log(err))
  }


  

  return (
    <SiteContext.Provider
    // TODO: Refactor value list to make more manageable
      value={{
        loggedIn,
        setLoggedIn,
        authData,
        productList,
        setProductList,
        fetchProductList,
        setAuthData,
        sendLogin,
        findProductName,
        fetchBundleList,
        bundleList,
        loading,
        setLoading,
        findBundle,
        updateOrderStatus,
        decodedJwt,
        getLocalJwt,
        logOut,
        logoutMsg,
        setLogoutMsg,
        checkJwt,
        fetchOrders,
        fetchOrder,
        fetchOrderCount,
        updateTracking
      }}>
      {children}
    </SiteContext.Provider>
  )
}

export default SiteContext
