import { Navigate } from "react-router-dom";
import { useState, useContext } from "react";
import SiteContext from "../contexts/SiteContext";
import Loader from "./Loader";


export default function Login({setJwt}) {
  const {logoutMsg, sendLogin, loggedIn, loading, setLoading} = useContext(SiteContext)

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true)
      sendLogin(name, password)
  };

  if (loggedIn) {
      return <Navigate to="/" />
  }


  return (
    <>
      {(logoutMsg !== '') && <h3>{logoutMsg}</h3>}
      <h1>You need to login</h1>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="name">
          Username
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <br/>
        <label htmlFor="password">
            Password
            <input 
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
        </label>
        <br />
        <input type="submit" value="submit" />
      </form>
      {loading && <Loader/>}
    </>
  );
}
