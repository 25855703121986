import { useEffect, useState } from 'react';

function Tracking({ setCourier, setTracking, courier, tracking, updateTracking, id }) {
	const [editing, setEditing] = useState(true);

	useEffect(() => {
		if (tracking == null) {
			setEditing(true);
		} else {
			setEditing(false);
		}
	}, []);

	// Stupid/Hacky way to show courier on first render after form submit.
	// TODO: More elagant solution
	const courierList = [
		'',
		'',
		'',
		'Fedex',
		'RL Carriers',
		'AAA Cooper',
		'Estes',
		'UPS Freight',
		'XPO logistics',
		'',
		'USPS',
		'UPS',
		'ABF Freight',
		'FedEx Freight',
	];

	const handleTrackingSubmit = () => {
		updateTracking(id, courier, tracking);
		setEditing(false);
	};

	const handleCourierChange = (e) => {
		setCourier(e);
	};

	const handleTrackingChange = (e) => {
		setTracking(e);
	};

	if (editing) {
		return (
			<form action="" onSubmit={(e) => handleTrackingSubmit(e)}>
				<label htmlFor="courier">Courier</label>
				<select
					name="courier"
					id="courier"
					value={courier === null ? '' : courier}
					onChange={(e) => handleCourierChange(e.target.value)}
					required={true}
				>
					<option value="">Select Courier...</option>
					<option value={3}>Fedex</option>
					<option value={4}>RL Carriers</option>
					<option value={5}>AAA Cooper</option>
					<option value={6}>Estes</option>
					<option value={7}>UPS Freight</option>
					<option value={8}>XPO logistics</option>
					<option value={10}>USPS</option>
					<option value={11}>UPS</option>
					<option value={12}>ABF Freight</option>
					<option value={13}>FedEx Freight</option>
				</select>
				<label htmlFor="Tracking">Tracking Number</label>
				<input
					type="text"
					name="Tracking"
					placeholder="Tracking Number"
					value={tracking === null ? '' : tracking}
					onChange={(e) => handleTrackingChange(e.target.value)}
					required={true}
				/>
				<input type="submit" />
			</form>
		);
	}

	return (
		<>
			<p>
				<strong>Courier:</strong> {courierList[courier]}
			</p>
			<p>
				<strong>Tracking:</strong>
				<a href={`https://www.aftership.com/track/${tracking}`} target="_blank" rel="noreferrer">
					{tracking}
				</a>
			</p>
			<button onClick={() => setEditing(true)}>Edit</button>
		</>
	);
}

export default Tracking;
