import React from 'react'
import Snake from '../assets/Snake.gif'

function Loader() {
  return (
    <div>
        <img src={Snake} alt="" className='loader'/>
    </div>
  )
}

export default Loader