import React from 'react';
import { useState, useEffect, useContext } from 'react';
import SiteContext from '../contexts/SiteContext';
import { useNavigate } from 'react-router-dom';
import { format, formatDistanceToNow } from 'date-fns';
import Loader from './Loader';
import PageGroup from './PageGroup';

export default function Orders() {
	const { fetchProductList, fetchBundleList, loading, setLoading, fetchOrders, fetchOrderCount } =
		useContext(SiteContext);
	let navigate = useNavigate();

	// States
	const [count, setCount] = useState(0);
	const [pag, setPag] = useState({
		limit: 20,
		start: 0,
		page: 1,
		sort: 'desc',
	});
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		setLoading(true);
		fetchProductList();
		fetchBundleList();
	}, []);

	useEffect(() => {
		(async () => {
			await fetchOrderCount().then((data) => {
				setCount(data);
				console.log('a');
			});
			await fetchOrders(pag.page, pag.sort, pag.limit).then((data) => {
				setOrders(data);
				console.log('b');
			});
			console.log('Orders UseEffect 2 pt 1 done');
		})().then(() => {
			setLoading(false);
			console.log('Orders UseEffect 2 pt 2 done.');
		});
	}, [pag]);

	useEffect(() => {});

	// COMPONENT FUNCTIONS

	const paginateGroup = (pageLimit = 4) => {
		let start = Math.floor((pag.page - 1) / pageLimit) * pageLimit;
		return new Array(pageLimit + 1).fill().map((_, idx) => start + idx + 1);
	};

	const handleOnClick = (id) => {
		setLoading(true);
		navigate(`/order/${id}`, {
			state: {
				id: id,
			},
		});
	};

	const handlePageChange = (newPage) => {
		setPag({ ...pag, page: newPage });
		setLoading(true);
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<PageGroup {...{ handlePageChange, paginateGroup, pag, count }} />
			<table>
				<thead>
					<tr>
						<th>Order Id</th>
						<th>Date</th>
						<th>Status</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{orders.map((order, index) => (
						<tr
							key={index}
							className="clickable-row"
							onClick={() => {
								handleOnClick(order.id);
							}}
						>
							<td>{order.orderId}</td>
							<td>
								{format(Date.parse(order.created_at), 'MMM, do, yyyy') +
									' - ' +
									formatDistanceToNow(Date.parse(order.created_at), { addSuffix: true })}
							</td>
							<td>{order.orderStatus}</td>
							<td>{order.total}</td>
							<td>
								{order.shippingAddress.firstName} {order.shippingAddress.lastName}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<PageGroup {...{ handlePageChange, paginateGroup, pag, count }} />
			{loading && <Loader />}
		</>
	);
}
